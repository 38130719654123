import React,{useState,useLayoutEffect,useRef} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../../components/Content/Title'
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser, faArrowDown,  faTimesCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import FormInput from '../../components/Form/Input'
import { useHistory, useParams } from 'react-router-dom';
import EditButton from '../../components/Form/EditButton'
import axios from 'axios'
import {AccordionForm} from '../../styles/Content'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {  toast } from 'react-toastify';
import Dialog from '../../components/Content/Dialog'
import { useSoundEffect } from '../../components/Content/Sounds';
import { Helmet } from 'react-helmet';

export default function ProductDetail(props) {  

  const {errorPlay,successPlay}= useSoundEffect()

  const buttonRef=useRef(null)
  const [search,setSearch]=useState('')
  const {sidebar}=useSelector(state=>state.site)
  const [edit,setEdit]=useState(false)
  const history=useHistory()

  const [name,setName]=useState()
  const [image,setImage]=useState('')
  const [description,setDescription]=useState('')
  const [price,setPrice]=useState('')
  const [stock,setStock]=useState('')
  const [barcode,setBarcode]=useState('')
  const [stockcode,setStockcode]=useState('')
  const [categories,setCategories]=useState([])
  const [selectedCategory,setSelectedCategory]=useState()
  const [categoryNames,setCategoryNames]=useState([])
  const [product,setProduct]=useState(null)
  const [units,setUnits]=useState([])
  const [selectedUnit,setSelectedUnit]=useState()
  const [unitNames,setUnitNames]=useState([])

  const [warranties,setWarranties]=useState([])
  const [selectedWarranty,setSelectedWarranty]=useState()
  const [warrantyNames,setWarrantyNames]=useState([])

  const [loading,setLoading]=useState(false)
  let {id} = useParams()
  id=id==undefined ? props.id : id
  

  const getCategories=async()=>{

    axios({
      method:'get',
      url:process.env.REACT_APP_API+process.env.REACT_APP_CATEGORIES+'?all=true',
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    }).then(res=>{
      setCategories(res.data.categories)
      setCategoryNames(res.data.categories.map(x=>x.name))
      
    })
    

  }

  const getUnits=async()=>{

    axios({
      method:'get',
      url:process.env.REACT_APP_API+process.env.REACT_APP_UNITS,
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    }).then(res=>{
      setUnits(res.data.units)
      setUnitNames(res.data.units.map(x=>x.name))
      
    })
    

  }

  const getWarranties=async()=>{

    axios({
      method:'get',
      url:process.env.REACT_APP_API+process.env.REACT_APP_WARRANTIES,
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    }).then(res=>{
      setWarranties(res.data.warranties)
      setWarrantyNames(res.data.warranties.map(x=>x.name))
      
    })
    

  }
  
  React.useEffect(() => {
    if(categories.length===0){
      getCategories()
    }
    if(units.length===0){
      getUnits()
    }
    if(warranties.length===0){
      getWarranties()
    }
    
  }, [])


  const getProduct=()=>{
    axios({
      method:'get',
      url:process.env.REACT_APP_API+(process.env.REACT_APP_PRODUCT.replace('{id}',id)),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      //setUser(response.data.data)
      setName(response.data.product.name)
      setStock(response.data.product.stock)
      setPrice(response.data.product.price)
      setImage(response.data.product.image)
      setDescription(response.data.product.description)
      setSelectedCategory(response.data.product.category)
      setBarcode(response.data.product.barcode)
      //setDate(response.data.data.registerDate)
      //setUserName(response.data.data.username)
      //setEdit(false)
    })
    .catch(res=>{
      // console.log(res.response)

    })
  }

  useLayoutEffect(() => {
    if(product===null){
      getProduct()
    }
  }, [product])

  

  const [deleteDialog,setDeleteDialog]=useState(false)
  const [deleteConfirm,setDeleteConfirm]=useState(false)

  const [updateDialog,setUpdateDialog]=useState(false)
  const [updateConfirm,setUpdateConfirm]=useState(false)

  
  const confirmDelete=async()=>{
    let set=await deleteProduct(true)
  }
  const confirmUpdate=async()=>{
    let set=await updateProduct(true)
  }
  const deleteProduct=async(confirmedDelete=false)=>{
    const seter=await setEdit(false)
    if(deleteConfirm==false && confirmedDelete!=true ){
      setDeleteDialog(true)
      return
    }else{
      setDeleteConfirm(false)
      setDeleteDialog(false)
    }
    const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
    axios({
      method:'post',
      url:process.env.REACT_APP_API+(process.env.REACT_APP_API_DELETE_USER.replace('-id-',id)),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      successPlay()
      history.push('/kullanici/liste')
    })
    .catch(error=>{
      errorPlay()
      toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
    })
  
  }
  const updateProduct=(e)=>{
    
    if(edit){
      if(e!=true){
        e.preventDefault()
        if(deleteConfirm==false){
          setUpdateDialog(true)
          return
        }
      }else{
        setUpdateConfirm(false)
        setUpdateDialog(false)
      }
      if(name!=''){
        const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
        axios({
          method:'post',
          url:process.env.REACT_APP_API+process.env.REACT_APP_UPDATE_PRODUCT,
          data:{
            product_id:id,
            name:name,
            description:description,
            price:price,
            stock:stock,
            image:image,
            barcode:barcode,
            stockcode:stockcode,
            category_id:(categories.find(x=>x.name==selectedCategory)).id,
            unit_id:(units.find(x=>x.name==selectedUnit)).id,
            warranty_id:1,
          },
          headers:{
            'Authorization':localStorage.getItem('token'),
            'Content-Type':'application/json'
          }
        })
        .then(response=>{
          successPlay()
          setEdit(false)
          toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
        })
        .catch(error=>{
          toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
          errorPlay()
        })
        
        
      }else{
        errorPlay()
        toast.error('Tüm alanları doldurmalısınız.')
      }
      
    }else{
      setEdit(true)
    }
    
  }

  const reloadData=async()=>{
    setEdit(false)
    let datas=await getProduct()
  }
  const getProductDetailContent=()=>{
    return (
      <form onSubmit={updateProduct}>
          <Title text={<><FontAwesomeIcon icon={faUser} /> Ürün Kartı</>} icon={
          <>
          <EditButton extras={[{text:'Ürünü Sil',styles:{marginRight:20,backgroundColor:'#ff7979',color:'#fff'},icon:<FontAwesomeIcon icon={faTimesCircle} />,onClick:deleteProduct}]} cancel={reloadData}  edit={edit} clicker={updateProduct} />
          </>} />
          <div style={{width:'105%'}}>
            <FormInput 
            value={search}
            setValue={setSearch}
            placeholder="Ara"
            inputClass='search-input' 
            rightIcon={<FontAwesomeIcon style={{cursor:'pointer'}} icon={faSearch} /> } />
          </div>

          
          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Ürün Özellikleri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionForm>
                  {/* {JSON.stringify(user)} */}
                 
                  <div className='input-group' >
                    <span>Ürün Adı :</span> <FormInput value={name}  disabled={!edit} setValue={setName} nomargin={true} placeholder="Ürün Adı" styles={{width:'105%'}} main type='text'  />
                  </div>

                  <div className='input-group' >
                    <span>Ürün Açıklaması :</span> <FormInput   disabled={!edit} value={description} setValue={setDescription} nomargin={true} placeholder="Ürün Açıklaması" styles={{width:'105%'}} main type='text'  />
                  </div>

                  <div className='input-group' >
                    <span>Stok Kodu :</span> <FormInput  disabled={!edit} value={stockcode} setValue={setStockcode} nomargin={true} placeholder="Stok Kodu" styles={{width:'105%'}} main type='text'  />
                  </div>

                  <div className='input-group' >
                    <span>Barkodu :</span> <FormInput  disabled={!edit} value={barcode} setValue={setBarcode} nomargin={true} placeholder="Barkodu" styles={{width:'105%'}} main type='text'  />
                  </div>

                  <div className='input-group' >
                    <span>Ürün Fiyatı :</span> <FormInput  disabled={!edit} value={price} setValue={setPrice} nomargin={true} placeholder="Ürün Fiyatı" styles={{width:'105%'}} main type='number'  />
                  </div>

                  <div className='input-group' >
                    <span>Stok Sayısı :</span> <FormInput  disabled={!edit} value={stock} setValue={setStock} nomargin={true} placeholder="Stok Sayısı" styles={{width:'105%'}} main type='number'  />
                  </div>
                 
                  

                  {/* <div className='input-group' >
                    <span>KDV Oranı :</span> <FormInput value={vat} setValue={setVat} nomargin={true} placeholder="KDV Oranı" styles={{width:'105%'}} main type='number'  />
                  </div> */}

                  {/* <div className='input-group' >
                    <span>İskonto Oranı :</span> <FormInput value={discount} setValue={setDiscount} nomargin={true} placeholder="İskonto Oranı" styles={{width:'105%'}} main type='number'  />
                  </div> */}

                  {
                    categoryNames.length > 0 ?
                    <div className='input-group' >
                      <span>Kategori :</span> <FormInput  disabled={!edit} value={selectedCategory} children={categoryNames} setValue={setSelectedCategory} nomargin={true} placeholder="Kategori" styles={{width:'105%'}} main type='combo'  />
                    </div>
                    :
                    <></>
                  }

                  {
                    unitNames.length > 0 ?
                    <div className='input-group' >
                      <span>Birim :</span> <FormInput  disabled={!edit} value={selectedUnit} children={unitNames} setValue={setSelectedUnit} nomargin={true} placeholder="Birim" styles={{width:'105%'}} main type='combo'  />
                    </div>
                    :
                    <></>
                  }

                  {/* {
                    warrantyNames.length > 0 ?
                    <div className='input-group' >
                      <span>Garanti :</span> <FormInput value={selectedWarranty} children={warrantyNames} setValue={setSelectedWarranty} nomargin={true} placeholder="Garanti" styles={{width:'105%'}} main type='combo'  />
                    </div>
                    :
                    <></>
                  } */}
                  <div className='input-group' >
                    <span>Fotoğraf :</span> <FormInput  disabled={!edit} value={image} setValue={setImage} nomargin={true}  styles={{width:'105%'}}  placeholder="Görsel seç" type='file'  />
                  </div>
                  

                
              </AccordionForm>

            </AccordionDetails>
          </Accordion>
          {
            edit ? 
            <div className='addButton'>
              <Button ref={buttonRef} type='submit' color="primary" variant="contained">Kaydet</Button> 
            </div>
            
            : null
          }
          </form>
    )
  }
  return (
    <>
      {
        props.id ? 
        <>
        </>
        :
        <Menu/>
      }
      <Helmet>
        <title>Ürün Kartı</title>
      </Helmet>
      <Dialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        setConfirm={confirmDelete}
        selectedId={1}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Ürün Silme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />

      <Dialog
        open={updateDialog}
        setOpen={setUpdateDialog}
        setConfirm={confirmUpdate}
        selectedId={1}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Ürün Güncelleme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />

      {
        props.id ?
        getProductDetailContent()
        :
        <Content sidebar={sidebar}>
        
          <ContentForm>
            
            {
              getProductDetailContent()
            }

          </ContentForm>

        </Content>
      }
    </>
  )
}