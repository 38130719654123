const LightTheme={

    body: '#ffffff',
    text: '#000',
    header:'rgba(255,181,0,.2)',
    headerText:'#000',
    spesificOpacity:.7,
    bars:'#F7B032',
    errorBackground:'#f44336',
    errorText:'#fff',
    errorPageBackground:'#f44355',
    content:'#eee',
    acordion:'#fff',
    shadow:'rgba(0,0,0,0.75)',
    popup:'rgba(0,0,0,0.5)',
    primary:'#F7B032',
}
const DarkTheme={
    body: '#363537',
    text: '#FAFAFA',
    header:'#555',
    headerText:'#fff',
    spesificOpacity:.4,
    bars:'#3f819d',
    errorBackground:'#f44336',
    errorText:'#fff',
    errorPageBackground:'#f44355',
    content:'#666',
    acordion:'#777',
    shadow:'rgba(0,0,0,0.75)',
    popup:'rgba(0,0,0,0.5)',
    primary:'#F7B032',
}

export {LightTheme,DarkTheme}