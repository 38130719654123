import React,{useRef,useEffect} from 'react'
import { ThemeProvider } from "styled-components";
import {GlobalStyle} from './styles/Global';
import {LightTheme,DarkTheme} from './styles/Theme'
import { useLocation,Route,Switch,Redirect} from "react-router-dom";
import {Routes} from './Routes'
import { useSelector,useDispatch } from 'react-redux';
import axios from 'axios'
import NotFound from './components/Error/NotFound';
import { ToastContainer, toast } from 'react-toastify';
import {logout} from './components/stores/Auth'
import 'react-toastify/dist/ReactToastify.css';
import "react-activity/dist/Sentry.css";
// import OneSignal from 'react-onesignal';
// import PWA from './components/Support/Pwa'

toast.configure()

function App() {

  // useEffect(() => {
  //   OneSignal.init({
  //     appId: "f1b85282-75e8-462e-8f65-3dd29ec0817f"
  //   });
  // }, []);

  const {login}=useSelector(state=>state.auth)
  const {theme,background}=useSelector(state=>state.site)
  const dispatch = useDispatch()

  axios.interceptors.response.use((response) => response, (error) => {
    if(error.response.status==401){
      dispatch(logout())
    }
    throw error;
  });


  const getRoutes=(route)=>{
    return Routes.map(route=>{
      if(route!=undefined){
        if(route.auth && !login){
          return <Redirect to="/giris-yap" />
        }
        if(route.props.items!=undefined && route.props.items.length>0){
          return route.props.items.map(item=>{
            return (
              <Route key={item.path}   exact={item.exact} path={route.path+item.path} component={item.component} />
            )
          })
        }
        
        return <Route key={route.path}  {...route} />
      }
    })
  }

  return (

        
      <ThemeProvider  theme={theme==='DarkTheme' ? DarkTheme : LightTheme}>
        <GlobalStyle background={`url("${background}")`} />
          <ToastContainer/>
          {/* <PWA/> */}
            <Switch >
            {
              getRoutes(Routes)
            }
            <Route path="*" render={() => <NotFound />} />
            </Switch>

      </ThemeProvider>
  );
}

export default App;