import React,{useState,createRef,useRef} from 'react'
import Menu from '../components/Navigation/Menu'
import {Content,ContentForm} from '../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../components/Content/Title'
import {useDispatch} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faHome, faWrench } from '@fortawesome/free-solid-svg-icons';
import NotifyContent from '../components/Content/NotifyContent'
import Support from '../components/Support'
import { Helmet } from 'react-helmet';
import ShortcutArea from '../components/Content/ShortcutArea/ShortcutArea'

export default function Home(props) {  
  const ref = createRef(null);

  const {sidebar}=useSelector(state=>state.site)

  const [notifyData,setNotifyData]=useState(
      [
        {
          name:'Yenilikler',
          icon:<FontAwesomeIcon icon={faWrench} />,
          items:[{title:'Ana Sayfa Hk.',content:'Bildirim ve Yenilikler ana sayfada yerini aldı. Arka plan sistemine entegresi için bekleniyor.'}]
        },
        {
          name:'Bildirimler',
          icon:<FontAwesomeIcon icon={faBell} />,
          items:[{title:'Bilmemne hk.',content:'TEST2 TE2ST'}]
        },
      ]
  )

  
  
  return (
    <React.Fragment>
      <Menu/>
      <Helmet>
        <title>Ana Sayfa</title>
      </Helmet>
      <Content ref={ref} sidebar={sidebar}  >
        <Support refer={ref} />
        <ContentForm>
          <form onSubmit={(e)=>{e.preventDefault()}}>
          <Title text={<><FontAwesomeIcon icon={faHome} /> Ana Sayfa</>} back={false} />
          <ShortcutArea/>
          
        
            <NotifyContent
            data={notifyData}
            />
          </form>
          


        </ContentForm>
            
      </Content>
    </React.Fragment>
  )
}
