import styled from "styled-components";
import { rgba } from 'polished'
import Sidebar from '../images/sidebar.jpeg'
import mami from '../images/mami.png'
const HeaderContainer=styled.div`
  display: flex;
  background-color: ${({theme})=>theme.header};
  position: relative;
  margin-left: ${(props)=>props.isOpen ? '300px' : '0px'};
  transition: all 0.3s ease-in-out;
  width: calc(100% - ${({isOpen})=>isOpen ? '300' : '0'}px);
  @media only screen and (max-width: 800px) {
    position: fixed;
    width: calc(100% - 0px);
    margin-left: 0px;
    z-index: 110;
  }
  right: 0;
  height: 50px;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  .bars{
    *{
      border-radius:6px;
    }
    button{
      background: ${({theme})=>theme.bars};
    }
    span{
      border-radius: 0%!important;
    }
    cursor: pointer;
    svg{
    font-size: 30px;
    }
    color: ${({theme})=>theme.headerText};
  }
  .headerItems{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    .headerItem{
      position: relative;
      transition: 600ms all;
      display: flex;
      align-items: center;
      border-radius: 5px;
      color: ${({theme})=>theme.headerText};
      &:hover{
        .dropBtn{
          background-color: ${({theme})=>rgba(theme.headerText,0.15)};
        }
      }
      .dropBtn{
        color:${({theme})=>theme.headerText};
        cursor: pointer;
        position: relative;
        transition: 600ms all;
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 5px;
        padding: 0 20px;
        cursor: pointer;
        margin-left: 20px;
        background-color: transparent;
        outline:0;
        border:0;
        span{
          position: absolute;
          bottom: -5px;
          right: 0px;
          font-size:10px;margin:9px;
        }
      }
      .sub{
        position: absolute;
        left: -100px;
        box-shadow: 0px 0px 7px 0px #000;
        top: 135%;
        width: 150px;
        display: flex;
        flex-direction: column;
        background-color: ${({theme})=>rgba(theme.headerText,0.7)};
        border-radius: 15px;
        padding: 10px;
        a{
          color: ${({theme})=>rgba(theme.header,1)};
          text-decoration: none;
          text-align: center;
          position: relative;
          border-radius: 10px;
          padding:7px 5px;
          cursor: pointer;
          svg{
            position: absolute;
            right: 5px;
          }
          &:hover{
            background-color: ${({theme})=>rgba(theme.headerText,0.15)};
          }

        }
      }
    }
  }

`

const SidebarContainer=styled.div`
  background-size: cover;
  background: linear-gradient(0deg, rgba(253,235,190,${({theme})=>theme.spesificOpacity}) 0%, rgba(254,223,146,${({theme})=>theme.spesificOpacity}) 100%);
  width: 300px;
  position: fixed;
  height: 100%;
  overflow-y:scroll;
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  &::-webkit-scrollbar{
    display: none;
  }
  z-index: 1;
  top: 0;
  transition: all 0.5s ease-in-out;
  left:${({isOpen})=>isOpen ? '0' : '-300px'};
  .logo{
    padding: 20px 0;
    img{
      padding: 0 50px;
      max-width: 220px;
    }    
  }
  @media only screen and (max-width: 800px) {
    background: linear-gradient(0deg, rgba(253,235,190,1) 0%, rgba(255,215,144,1) 100%)!important;
    background-color: linear-gradient(0deg, rgba(2253,235,190,1) 0%, rgba(255,215,144,1) 100%)!important;
    z-index: 100;
    width: 100%;
    left:${({isOpen})=>isOpen ? '0' : '-150%'};
    .logo{
      padding: 50px 0;
      img{
        padding: 0 50px;
        max-width: 220px;
      }    
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  
  input{
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    border:0;
    outline: 0;
    background-color: ${({theme})=>theme.header};
    color: ${({theme})=>theme.headerText};
    font-size: 14px;
  }
  .footer{
    &::before{
        z-index:19999;
        position:fixed;
        content:'';
        width:320px;
        height:470px;
        background:url();
        background-size:cover;
        opacity: 0;
        left:-100vw;
        transition: all 0.3s ease-in-out;
      }
    &:hover{
      &::before{
        left:0;
        opacity: 1;
      }
    }
    width: 100%;
    display:flex;
    justify-content: center;
    align-items:center;
    border-top:20px solid ${({theme})=>theme.header};
    padding: 20px 0;
    color: ${({theme})=>theme.headerText};
    a{
      color: ${({theme})=>theme.headerText};
      text-decoration: none;
      text-align: center;
      margin-right:5px;
      font-weight: 400;
    }
  }
  .sideHr{
  }
  .navigation{
    img{
      width: 20px;
      height: 20px;
      margin-bottom: -5px;
    }
    
    width: 100%;
    display: flex;
    max-height: calc(100vh - 20px);
    overflow-y:scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    &::-webkit-scrollbar{
      display: none;
    }
    padding-bottom:40px;
    flex-direction: column;
    align-items: center;
    button{
      border-radius: 15px;
      font-size:12px!important;
      background:${({theme})=>theme.header};
      &:hover{
        background:${({theme})=>rgba(theme.header,.6)};
      }
    }
    .subitem{
      background:${({theme})=>rgba(theme.header,0.45)};
      margin-top:7px;
      &:hover{
        background:${({theme})=>rgba(theme.header,.8)};
      }
    }
    &>button,&>div,&>a{
      width: 80%;
      text-decoration: none;
      position: relative;
      margin-top:17px;
      &>a,&>div,button{
        font-size:12px!important;
        position: relative;
        a{
          text-decoration: none;
          color: ${({theme})=>theme.headerText};
          width: 70%;
        }
        width: 100%;
        text-decoration: none;
        color: ${({theme})=>theme.headerText};
        padding: 15px ;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        transition: all 0.3s ease-in-out;
        svg{
          font-size: 14px!important;
          position: absolute;
          right: 5px;
          font-size: 20px;
        }
        .ico,span svg{
          position: relative;
          font-size: 20px;
        }
        
        &:hover{
          color: ${({theme})=>theme.headerText};
        }
      }
      .active{
        button,a{
        }
        color: ${({theme})=>theme.headerText};
      }
      .subitem{
        width: 90%;
        margin-left:5%;

        svg{
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          font-size:12px;
        }
      }
    }
    .active{
      button{
        background-color:${({theme})=>rgba(theme.headerText,0.1)};
      } 
    }
    
  }

`

export {HeaderContainer,SidebarContainer}