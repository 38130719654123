import React,{useState,useEffect} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../../components/Content/Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faUserPlus, faClipboardCheck, faHockeyPuck } from '@fortawesome/free-solid-svg-icons';
import {AccordionForm} from '../../styles/Content'

import Button from '@material-ui/core/Button';
import FormInput  from '../../components/Form/Input'

import axios from 'axios'
import { Sentry } from "react-activity";
import {  toast } from 'react-toastify';
import { Link,useHistory } from 'react-router-dom';
import {useSoundEffect} from '../../components/Content/Sounds' 
import { Helmet } from 'react-helmet'
import { Checkbox } from '@material-ui/core'
export default function ProductAdd() {

  const {successPlay,errorPlay}=useSoundEffect()

  const history=useHistory()
  const {sidebar}=useSelector(state=>state.site)


  const [name,setName]=useState('')
  const [image,setImage]=useState('')
  const [description,setDescription]=useState('')
  const [price,setPrice]=useState('')
  const [stock,setStock]=useState('')
  const [barcode,setBarcode]=useState('')
  const [stockcode,setStockcode]=useState('')
  const [vat,setVat]=useState('')
  const [discount,setDiscount]=useState('')
  const [unit_id,setUnit_id]=useState('1')
  const [warranty_id,setWarranty_id]=useState('1')
  
  
  const [categories,setCategories]=useState([])
  const [selectedCategory,setSelectedCategory]=useState()
  const [categoryNames,setCategoryNames]=useState([])
  
  const [units,setUnits]=useState([])
  const [selectedUnit,setSelectedUnit]=useState()
  const [unitNames,setUnitNames]=useState([])

  const [warranties,setWarranties]=useState([])
  const [selectedWarranty,setSelectedWarranty]=useState()
  const [warrantyNames,setWarrantyNames]=useState([])

  const [loading,setLoading]=useState(false)

  const getCategories=async()=>{

    axios({
      method:'get',
      url:process.env.REACT_APP_API+process.env.REACT_APP_CATEGORIES,
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    }).then(res=>{
      setCategories(res.data.categories)
      setCategoryNames(res.data.categories.map(x=>x.name))
      
    })
    

  }

  const getUnits=async()=>{

    axios({
      method:'get',
      url:process.env.REACT_APP_API+process.env.REACT_APP_UNITS,
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    }).then(res=>{
      setUnits(res.data.units)
      setUnitNames(res.data.units.map(x=>x.name))
      
    })
    

  }

  const getWarranties=async()=>{

    axios({
      method:'get',
      url:process.env.REACT_APP_API+process.env.REACT_APP_WARRANTIES,
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    }).then(res=>{
      setWarranties(res.data.warranties)
      setWarrantyNames(res.data.warranties.map(x=>x.name))
      
    })
    

  }
  
  useEffect(() => {
    if(categories.length===0){
      getCategories()
    }
    if(units.length===0){
      getUnits()
    }
    if(warranties.length===0){
      getWarranties()
    }
    
  }, [])
  

  const addProduct=async(e)=>{
    e.preventDefault()
    if(name!='' && selectedCategory!=undefined && selectedUnit!=undefined ){

      const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
      console.log(units)
      axios({
        method:'post',
        url: process.env.REACT_APP_API+process.env.REACT_APP_ADD_PRODUCT,
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        data:{
          name:name,
          description:description,
          price:price,
          stock:stock,
          image:image,
          barcode:barcode,
          stockcode:stockcode,
          category_id:(categories.find(x=>x.name==selectedCategory)).id,
          unit_id:(units.find(x=>x.name==selectedUnit)).id,
          warranty_id:1,
        }
      })
      .then(res=>{
        toast.update(loader, { render: res.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
        successPlay()
        setName('')
        setDescription('')
        setPrice(0)
        setStockcode('')
        setImage('')
        setBarcode('')
        setSelectedUnit('')

        setDiscount(0)
        setSelectedCategory('')
        setStock(0)
      })
      .catch(error=>{
        console.log(error)
        toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
        errorPlay()
      })
      
    }else{
      errorPlay()
      setLoading(false)
      toast.error('Tüm alanları doldurmalısınız')
    }
  }
  
  return (
    <>
      <Menu/>
      <Helmet>
        <title>Ürün Oluştur</title>
      </Helmet>
      <Content sidebar={sidebar}>
        <ContentForm>
          <form onSubmit={addProduct}>
          <Title text={<><FontAwesomeIcon icon={faHockeyPuck} /> Yeni Ürün Oluştur</>} />
          
          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Ürün Özellikleri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionForm>
                
                  <div className='input-group' >
                    <span>Ürün Adı :</span> <FormInput value={name} setValue={setName} nomargin={true} placeholder="Ürün Adı" styles={{width:'105%'}} main type='text'  />
                  </div>

                  <div className='input-group' >
                    <span>Ürün Açıklaması :</span> <FormInput value={description} setValue={setDescription} nomargin={true} placeholder="Ürün Açıklaması" styles={{width:'105%'}} main type='text'  />
                  </div>

                  <div className='input-group' >
                    <span>Stok Kodu :</span> <FormInput value={stockcode} setValue={setStockcode} nomargin={true} placeholder="Stok Kodu" styles={{width:'105%'}} main type='text'  />
                  </div>

                  <div className='input-group' >
                    <span>Barkodu :</span> <FormInput value={barcode} setValue={setBarcode} nomargin={true} placeholder="Barkodu" styles={{width:'105%'}} main type='text'  />
                  </div>

                  <div className='input-group' >
                    <span>Ürün Fiyatı :</span> <FormInput value={price} setValue={setPrice} nomargin={true} placeholder="Ürün Fiyatı" styles={{width:'105%'}} main type='number'  />
                  </div>

                  <div className='input-group' >
                    <span>Stok Sayısı :</span> <FormInput value={stock} setValue={setStock} nomargin={true} placeholder="Stok Sayısı" styles={{width:'105%'}} main type='number'  />
                  </div>
                 
                  

                  {/* <div className='input-group' >
                    <span>KDV Oranı :</span> <FormInput value={vat} setValue={setVat} nomargin={true} placeholder="KDV Oranı" styles={{width:'105%'}} main type='number'  />
                  </div> */}

                  {/* <div className='input-group' >
                    <span>İskonto Oranı :</span> <FormInput value={discount} setValue={setDiscount} nomargin={true} placeholder="İskonto Oranı" styles={{width:'105%'}} main type='number'  />
                  </div> */}

                  {
                    categoryNames.length > 0 ?
                    <div className='input-group' >
                      <span>Kategori :</span> <FormInput value={selectedCategory} children={categoryNames} setValue={setSelectedCategory} nomargin={true} placeholder="Kategori" styles={{width:'105%'}} main type='combo'  />
                    </div>
                    :
                    <></>
                  }

                  {
                    unitNames.length > 0 ?
                    <div className='input-group' >
                      <span>Birim :</span> <FormInput value={selectedUnit} children={unitNames} setValue={setSelectedUnit} nomargin={true} placeholder="Birim" styles={{width:'105%'}} main type='combo'  />
                    </div>
                    :
                    <></>
                  }

                  {/* {
                    warrantyNames.length > 0 ?
                    <div className='input-group' >
                      <span>Garanti :</span> <FormInput value={selectedWarranty} children={warrantyNames} setValue={setSelectedWarranty} nomargin={true} placeholder="Garanti" styles={{width:'105%'}} main type='combo'  />
                    </div>
                    :
                    <></>
                  } */}
                  <div className='input-group' >
                    <span>Fotoğraf :</span> <FormInput value={image} setValue={setImage} nomargin={true}  styles={{width:'105%'}}  placeholder="Görsel seç" type='file'  />
                  </div>

              </AccordionForm>

            </AccordionDetails>
          </Accordion>

          <div className='addButton'>
            
            <Button type='submit' disabled={loading} color="primary" variant="contained">{loading ? <Sentry size={30} color={'#000'} /> : 'Ürün Oluştur'}</Button>
          </div>

          </form>

        </ContentForm>

      </Content>
      
    </>
  )
}