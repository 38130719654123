import styled from "styled-components";
import { rgba } from "polished";
const FormElement=styled.div`
  position:relative;
  padding-left:calc(4px + ${props=>props.paddingLeft}px);
  background-color:${({theme})=>theme.content};
  padding-right:calc(4px + ${props=>props.paddingRight}px);
  margin-right:5%;
  border-radius:5px;
  margin-bottom:2.5%;
  
  .img-upload{
    width:100%;
    border-radius:5px;
    min-height:40px;
    display:flex;
    img{
      max-height:30px;
    }
    align-items:center;
    justify-content:center;
    cursor:pointer;
    margin-bottom:-5px;
    label{

      width:100%;
      height:100%;
    }
  }
  .left{
    position:absolute;
    top:50%;
    left:5px;
    transform:translateY(-50%);
  }
  .right{
    position:absolute;
    top:50%;
    right:5px;
    transform:translateY(-50%);
  }
  
  &>div{
    position:absolute;
    transform:scale(0);
    z-index:100;
    visibility:hidden;
    top:-40px;
    &:focus,&:hover{
      transform:scale(1) !important;
      visibility:visible!important;
      top:calc(100% + 10px)!important;
    }
    left:0;
    width:100%;
    border-radius:5px;
    transition:300ms all;
    max-height:150px;
    overflow-y:scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
    &::-webkit-scrollbar{
      display: none;
    }
    ul{
      padding:10px 0px;
      background-color:${({theme})=>theme.content};
      color:${({theme})=>theme.text};
      list-style:none;
      border-bottom-left-radius:5px;
      border-bottom-right-radius:5px;
      padding-inline-start:0;
      li{
        padding:3px 10px;
        line-height:1.5;
        cursor:pointer;
        &:nth-child(n+2){
          background-color:${({theme})=>theme.faBackspace};
        }
        &:not(:last-child){
        }
        &:hover{
          background-color:${({theme})=>rgba(theme.text,0.1)};
        }
        margin:4px;
      }
    }
    
  }
  
  input{
    width:100%;
    background-color:transparent;
    color:${({theme})=>theme.text};
    outline:0;
    border:0;
    
    height:40px;
    &::placeholder{
      color:${({theme})=>rgba(theme.text,.5)};
    }
  }
  &:focus-within{
    &>div{
      transform:scale(1) !important;
      visibility:visible!important;
      top:calc(100% + 10px)!important;
    }import { rgba } from 'polished';

    outline:.1px solid #000;
  }
`
export {FormElement}