import React,{useState,useEffect} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../../components/Content/Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faUserPlus, faClipboardCheck, faAlignRight } from '@fortawesome/free-solid-svg-icons';
import {AccordionForm} from '../../styles/Content'

import Button from '@material-ui/core/Button';
import FormInput  from '../../components/Form/Input'

import axios from 'axios'
import { Sentry } from "react-activity";
import {  toast } from 'react-toastify';
import { Link,useHistory } from 'react-router-dom';
import {useSoundEffect} from '../../components/Content/Sounds' 
import { Helmet } from 'react-helmet'
import { Checkbox } from '@material-ui/core'
export default function UnitAdd() {

  const {successPlay,errorPlay}=useSoundEffect()

  const history=useHistory()
  const {sidebar}=useSelector(state=>state.site)


  const [name,setName]=useState('')
  const [pdf,setPdf]=useState('')
 
  
  const [loading,setLoading]=useState(false)


  const addUnit=async(e)=>{
    e.preventDefault()
      //do something else
    
    // setLoading(true)
    if(name!='' ){

      const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
      axios({
        method:'post',
        url: process.env.REACT_APP_API+process.env.REACT_APP_ADD_CATALOG,
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        data:{
          name:name,
          pdf:pdf
        }
      })
      .then(res=>{
        toast.update(loader, { render: res.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
        successPlay()
        setName('')
        setPdf('')
      })
      .catch(error=>{
        console.log(error)
        toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
        errorPlay()
      })
      
    }else{
      errorPlay()
      setLoading(false)
      toast.error('Tüm alanları doldurmalısınız')
    }
  }
  
  return (
    <>
      <Menu/>
      <Helmet>
        <title>Katalog Oluştur</title>
      </Helmet>
      <Content sidebar={sidebar}>
        <ContentForm>
          <form onSubmit={addUnit}>
          <Title text={<><FontAwesomeIcon icon={faAlignRight} /> Yeni Katalog Oluştur</>} />
          
          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Katalog Özellikleri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionForm>
                
                  <div className='input-group' >
                    <span>Katalog Adı :</span> <FormInput value={name} setValue={setName} nomargin={true} placeholder="Katalog Adı" styles={{width:'105%'}} main type='text'  />
                  </div>

                  <div className='input-group' >
                    <span>Katalog  :</span> <FormInput value={pdf} setValue={setPdf} nomargin={true} placeholder="PDF seç" styles={{width:'105%'}} main type='file' file="pdf"  />
                  </div>

              </AccordionForm>

            </AccordionDetails>
          </Accordion>

          <div className='addButton'>
            
            <Button type='submit' disabled={loading} color="primary" variant="contained">{loading ? <Sentry size={30} color={'#000'} /> : 'Katalog Oluştur'}</Button>
          </div>

          </form>

        </ContentForm>

      </Content>
      
    </>
  )
}