import React,{useState,useEffect} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../../components/Content/Title'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faUserPlus, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import {AccordionForm} from '../../styles/Content'

import Button from '@material-ui/core/Button';
import FormInput  from '../../components/Form/Input'

import axios from 'axios'
import { Sentry } from "react-activity";
import {  toast } from 'react-toastify';
import { Link,useHistory } from 'react-router-dom';
import {useSoundEffect} from '../../components/Content/Sounds' 
import { Helmet } from 'react-helmet'
import { Checkbox } from '@material-ui/core'
export default function CategoryAdd() {

  const {successPlay,errorPlay}=useSoundEffect()

  const history=useHistory()
  const {sidebar}=useSelector(state=>state.site)
  const [name,setName]=useState('')
  const [image,setImage]=useState('')
  const [description,setDescription]=useState('')

  const [groupNames,setGroupNames]=useState([])
  const [groups,setGroups]=useState([])
  const [selectedGroup,setSelectedGroup]=useState()
  

  const [subMode,setSubMode]=useState(false)

  const [loading,setLoading]=useState(false)

  const getGroups=async()=>{

    axios({
      method:'get',
      url:process.env.REACT_APP_API+process.env.REACT_APP_CATEGORIES,
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    }).then(res=>{
      setGroupNames(res.data.categories.map(x=>x.name))
      setGroups(res.data.categories)
      console.log(res)
    })
    

  }
  useEffect(() => {
    if(groupNames.length===0){
      getGroups()
    }

    
  }, [])
  

  const addCategory=async(e)=>{
    e.preventDefault()
      //do something else
    
    // setLoading(true)
    if(name!='' ){

      const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
      const push=await subMode ? {
        name:name,
        description:description,
        image:image,
        parent_id:groups.find(x=>x.name===selectedGroup).id,
      } :
      {
        name:name,
        image:image,
        description:description,
      }
      axios({
        method:'post',
        url: process.env.REACT_APP_API+process.env.REACT_APP_ADD_CATEGORY,
        headers: {
          'Authorization': localStorage.getItem('token'),
          'Content-Type': 'application/json'
        },
        data:push
      })
      .then(res=>{
        toast.update(loader, { render: res.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
        successPlay()
        setName('')
        setDescription('')
        setSelectedGroup('')
        setSubMode(false)
        setImage('')
      })
      .catch(error=>{
        toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
        errorPlay()
      })
      
    }else{
      errorPlay()
      setLoading(false)
      toast.error('Tüm alanları doldurmalısınız')
    }
  }
  
  return (
    <>
      <Menu/>
      <Helmet>
        <title>Kategori Oluştur</title>
      </Helmet>
      <Content sidebar={sidebar}>
        <ContentForm>
          <form onSubmit={addCategory}>
          <Title text={<><FontAwesomeIcon icon={faClipboardCheck} /> Yeni Kategori Oluştur</>} />
          
          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Kategori Özellikleri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionForm>
                
                  <div className='input-group' style={{width:'107%'}}>
                    <FormInput value={name} setValue={setName} nomargin={true} placeholder="Kategori Adı" styles={{width:'105%'}} mainStyle={{width:'107%'}} type='text'  />
                  </div>

                  <div className='input-group' style={{width:'107%'}}>
                    <FormInput value={description} setValue={setDescription} nomargin={true} placeholder="Kategori Açıklaması" styles={{width:'105%'}} mainStyle={{width:'107%'}} type='text'  />
                  </div>

                  <div className='input-group' style={{width:'107%'}}>
                    <FormInput value={image} setValue={setImage} nomargin={true}  styles={{width:'105%'}} mainStyle={{width:'107%'}} type='file'  />
                    
                  </div>

                  <div>
                    <label style={{cursor:'pointer'}}>
                      <Checkbox checked={subMode} onChange={()=>setSubMode(!subMode)} /> Bu bir alt kategori
                    </label>
                  </div>

                  {
                    subMode ? 
                    <>
                    <div className='input-group' style={{width:'107%',marginTop:40}}>
                      <FormInput value={selectedGroup} children={groupNames} setValue={setSelectedGroup} nomargin={true} placeholder="Üst Kategori" styles={{width:'105%'}} mainStyle={{width:'107%'}} type='combo'  />
                    </div>
                    </>
                    :
                    <></>
                  }


              </AccordionForm>

            </AccordionDetails>
          </Accordion>

          <div className='addButton'>
            
            <Button type='submit' disabled={loading} color="primary" variant="contained">{loading ? <Sentry size={30} color={'#000'} /> : 'Kategori Oluştur'}</Button>
          </div>



          </form>

        </ContentForm>

      </Content>
      
    </>
  )
}