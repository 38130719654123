import React,{useState,useLayoutEffect,useRef,useEffect} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import Title from '../../components/Content/Title'
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUser,   faArrowDown,  faTrashAlt, faSdCard, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import FormInput from '../../components/Form/Input'
import { useHistory, useParams } from 'react-router-dom';
import EditButton from '../../components/Form/EditButton'
import axios from 'axios'
import {AccordionForm} from '../../styles/Content'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {  toast } from 'react-toastify';
import Dialog from '../../components/Content/Dialog'
import { useSoundEffect } from '../../components/Content/Sounds';
import { Helmet } from 'react-helmet';
import DataTable from 'react-data-table-component';
import { Sentry } from "react-activity";
import ThreeDot from '../../components/Form/ThreeDot'
import {Link} from 'react-router-dom'


export default function CategoryDetail(props) {  
  const sortIcon = <FontAwesomeIcon style={{transition:'300ms all',fontWeight:'400'}}   icon={faArrowDown} />;
  const [selectedRows,setSelectedRows]=useState([])
  const [loading,setLoading]=useState(true)
  const [multiDeleteDialog,setMultiDeleteDialog]=useState(false)
  const [multiDeleteConfirm,setMultiDeleteConfirm]=useState(false)
  const [deleteId,setDeleteId]=useState(null)
  const [groupNames,setGroupNames]=useState([])
  const [groups,setGroups]=useState([])
  const [selectedGroup,setSelectedGroup]=useState()
  const columns = [
      {
          name: 'ID',
          selector: row => row.id,
          sortable: true,
      },
      {
          name: 'Adı',
          selector: row => row.name,
          sortable: true,
      },
      
      {
          name: <ThreeDot items={
          <>
            <Link to={{
            pathname:'/kategori/guncelle',
            state:{
              ids:selectedRows
            }
          }} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faSdCard} /> Kategorilerin Kartı</Link>
          <a onClick={()=>multiDelete()} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faTrashAlt} /> Seçilenleri Sil</a>
          </>
          }  />,
          selector: row => 
          <ThreeDot items={
          <>
            <a onClick={()=>getCategory(row.id)} ><FontAwesomeIcon icon={faSdCard} /> Kategori Kartı</a>
            <a onClick={()=>{deleteCategory(row.id)}}><FontAwesomeIcon icon={faTrashAlt} /> Sil</a>
          </>
          } id={row.id} />,
          sortable: false,
          maxWidth: '3%',
      }
  ];
  const {errorPlay,successPlay}= useSoundEffect()

  const buttonRef=useRef(null)
  const [search,setSearch]=useState('')
  const {sidebar}=useSelector(state=>state.site)
  const [edit,setEdit]=useState(false)
  const history=useHistory()
 
  const [name,setName]=useState()
  const [image,setImage]=useState()
  const [subs,setSubs]=useState([])
  const [category,setCategory]=useState(null)
  let {id} = useParams()

  id=id==undefined ? props.id : id
  
  const getCategory=(category_id=id,backgroup=groups)=>{
    axios({
      method:'get',
      url:process.env.REACT_APP_API+(process.env.REACT_APP_CATEGORY.replace('{id}',category_id)),
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      if(response.data.category.parent_id!=null){

        setSelectedGroup((backgroup.find(x=>x.id==response.data.category.parent_id)).name)
      }
      setName(response.data.category.name)
      setSubs(response.data.category.sub_categories)
      setImage(response.data.category.image)
      //setUserName(response.data.data.username)
      setLoading(false)
      //setEdit(false)
    })
    .catch(res=>{
    //   // console.log(res.response)

    })
  }

 

  

  const [deleteDialog,setDeleteDialog]=useState(false)
  const [deleteConfirm,setDeleteConfirm]=useState(false)

  const [updateDialog,setUpdateDialog]=useState(false)
  const [updateConfirm,setUpdateConfirm]=useState(false)

  
  const multiDelete=()=>{
    if(multiDeleteConfirm==false && multiDeleteDialog==false){
      setMultiDeleteDialog(true)
    }
  }
  const confirmDelete=async()=>{
    let set=await deleteCategory(deleteId,true)
  }
  const confirmUpdate=async()=>{
    let set=await updateCategory(true)
  }
  const deleteCategory=(id,confirmed=false)=>{
    if(deleteConfirm==false && confirmed==false ){
      setDeleteId(id)
      setDeleteDialog(true)
      return
    }else{
      setDeleteId(null)
      setDeleteConfirm(false)
      setDeleteDialog(false)
    }
    const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
    axios({
      method: 'post',
      url: process.env.REACT_APP_API+process.env.REACT_APP_DELETE_CATEGORY,
      data:{category_id:id},
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    .then(response=>{
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      setSubs(subs.filter(item=>item.id!==id))
      successPlay()
      getCategory()
    })
    .catch(error=>{
      errorPlay()
      toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
    })
  }
  const updateCategory=(e)=>{
    if(edit){
      if(e!=true){
        e.preventDefault()
        if(deleteConfirm==false){
          setUpdateDialog(true)
          return
        }
      }else{
        setUpdateConfirm(false)
        setUpdateDialog(false)
      }
      if(name!=''){
        const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
        axios({
          method:'post',
          url:process.env.REACT_APP_API+process.env.REACT_APP_UPDATE_CATEGORY,
          data:{
            parent_id:groups.find(x=>x.name=selectedGroup)==undefined ? null : groups.find(x=>x.name=selectedGroup).id,
            category_id:id,
            name:name,
            image:image
          },
          headers:{
            'Authorization':localStorage.getItem('token'),
            'Content-Type':'application/json'
          }
        })
        .then(response=>{
          successPlay()
          setEdit(false)
          toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
        })
        .catch(error=>{
          console.log(error)
          toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
          errorPlay()
        })
        
        
      }else{
        errorPlay()
        toast.error('Tüm alanları doldurmalısınız.')
      }
      
    }else{
      setEdit(true)
    }
    
  }
  const getGroups=async()=>{

    axios({
      method:'get',
      url:process.env.REACT_APP_API+process.env.REACT_APP_CATEGORIES,
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    }).then(async res=>{
      const seter=await setGroupNames(res.data.categories.map(x=>x.name))
      const update=await setGroups(res.data.categories)
      getCategory(id,res.data.categories)
    })

  }
  useEffect(() => {
    if(groupNames.length===0){
      getGroups()
    }

    
  }, [])
  const reloadData=async()=>{
    setEdit(false)
    let datas=await getCategory()
  }
  const getCategoryDetailContent=()=>{
    return (
      <form onSubmit={updateCategory}>
          <Title text={<><FontAwesomeIcon icon={faUser} /> Kategori Kartı</>} icon={
          <>
          <EditButton extras={[{text:'Kategoriyi Sil',styles:{marginRight:20,backgroundColor:'#ff7979',color:'#fff'},icon:<FontAwesomeIcon icon={faTimesCircle} />,onClick:deleteCategory}]} cancel={reloadData}  edit={edit} clicker={updateCategory} />
          </>} />
          <div style={{width:'105%'}}>
            <FormInput 
            value={search}
            setValue={setSearch}
            placeholder="Ara"
            inputClass='search-input' 
            rightIcon={<FontAwesomeIcon style={{cursor:'pointer'}} icon={faSearch} /> } />
          </div>

          
          <Accordion
              className='accordion-form'
              defaultExpanded={true}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Kategori Özellikleri</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionForm>
                  {/* {JSON.stringify(user)} */}
                  <div className='input-group' style={{width:'44%'}} >
                    <span>Kategori Adı :</span>
                    <FormInput value={name} setValue={setName} mainStyle={{width:'43%'}} disabled={!edit} nomargin placeholder="Adı"  type='text'  />
                  </div>

                  <div className='input-group' style={{width:'44%'}} >
                    <span>Fotoğraf :</span>
                    <FormInput value={image} setValue={setImage} mainStyle={{width:'43%'}} disabled={!edit} nomargin placeholder="Adı"  type='file'  />
                  </div>

                  <div className='input-group' style={{width:'44%'}} >
                    <span>Üst Kategori :</span>
                    <FormInput value={selectedGroup} children={groupNames} setValue={setSelectedGroup} disabled={!edit} nomargin={true} placeholder="Üst Kategori" mainStyle={{width:'43%'}} type='combo'  />
                  </div>

                
              </AccordionForm>

            </AccordionDetails>
          </Accordion>
          <DataTable
              pagination
              responsive
              paginationComponentOptions={{ noRowsPerPage: true }}
              sortIcon={sortIcon}
              defaultSortFieldId={1}
              onRowDoubleClicked={(e,rowData)=>{
                history.push(`/kategori/guncelle/${e.id}`)
                getCategory(e.id)
              }}
              
              highlightOnHover
              pointerOnHover
              noDataComponent={<div style={{padding:20}}>{loading ? <Sentry size={34} />: <h1>Alt kategori bulunamadı</h1>}</div>}
              columns={columns}
              data={subs}
          />
          {
            edit ? 
            <div className='addButton'>
              <Button ref={buttonRef} type='submit' color="primary" variant="contained">Kaydet</Button> 
            </div>
            
            : null
          }
          </form>
    )
  }
  return (
    <>
      {
        props.id ? 
        <>
        </>
        :
        <Menu/>
      }
      <Helmet>
        <title>Kategori Kartı</title>
      </Helmet>
      <Dialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        setConfirm={confirmDelete}
        selectedId={1}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Kategori Silme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />

      <Dialog
        open={updateDialog}
        setOpen={setUpdateDialog}
        setConfirm={confirmUpdate}
        selectedId={1}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Kategori Güncelleme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />

      {
        props.id ?
        getCategoryDetailContent()
        :
        <Content sidebar={sidebar}>
        
          <ContentForm>
            
            {
              getCategoryDetailContent()
            }

          </ContentForm>

        </Content>
      }
    </>
  )
}