import React,{useState} from 'react'
import {AuthContainer,useStyles} from '../../styles/AuthStyle'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Copyright from '../../components/Copyright'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import {login} from '../../components/stores/Auth'
import axios from 'axios';
import { Sentry } from "react-activity";
import rentAs from '../../images/dehasoft.png'
export default function Login() {

  const dispatch = useDispatch()
  const history=useHistory()
  
  localStorage.removeItem('background')
  const classes = useStyles()

  const [error,setError]=useState('')
  const [post,setPost]=useState(false)
  const [email,setEmail] = useState(localStorage.getItem('email')!=null && localStorage.getItem('email')!=undefined  ? localStorage.getItem('email') : 'm@s')
  const [password,setPassword] = useState(localStorage.getItem('password')!=null && localStorage.getItem('password')!=undefined  ? localStorage.getItem('password') : '1')
  const [remember,setRemember] = useState(localStorage.getItem('remember')=='true' ? true : false)
  
  const labelProps= {
    classes: {
      root: classes.cssLabel,
      focused: classes.cssFocused,
    },
  }
  const inputProps= {
    classes: {
      root: classes.cssOutlinedInput,
      focused: classes.cssFocused,
      notchedOutline: classes.notchedOutline,
    }
  }
  
 
  const loginCheck=async (e)=>{

    e.preventDefault()
    
    setPost(true)
    axios({
      method: 'post',
      url: process.env.REACT_APP_API+process.env.REACT_APP_LOGIN,
      data: {
        email: email,
        password: password
      },
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST',
      }
    })
    .then(function (response) {
      if(response.data.user.status=='Yönetici'){
        setError('')
        dispatch(login({
          user:response.data.user,
          token:response.data.tokenType+' '+response.data.token,
          status:'admin',
        }))
        if(remember){
          localStorage.setItem('email',email)
          localStorage.setItem('password',password)
          localStorage.setItem('remember','true')
        }
        history.push('/')
      }else{
        setError('Panel için giriş yetkiniz bulunmamaktadır.')
      }
      
      
    })
    .catch(function (error) {
      setPost(false)
      setError(error.response.data.message)
      
    });

  }


  return (
    <AuthContainer>
      <form onSubmit={loginCheck}>
        <img src={rentAs}  style={{zIndex:99}} onDragStart={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()} />
        <TextField 
        InputLabelProps={labelProps} InputProps={inputProps}
        value={email} onChange={(e)=>setEmail(e.target.value)}
        autoComplete='off' required id="outlined-basic" label="E Posta" variant="outlined" />

        <TextField InputLabelProps={labelProps} InputProps={inputProps} 
        value={password} onChange={(e)=>setPassword(e.target.value)}
        autoComplete='off' required type={'password'} id="outlined-basic" label="Parola" variant="outlined" />
        <div className='remember'>
          <FormControlLabel
          label="Beni hatırla"
          control={ <Checkbox checked={remember} onChange={()=>setRemember(!remember)} size="small" inputProps={{ 'aria-label': 'checkbox with small size' }} />}
          />
        </div>
        {
          error!='' ? <div className='error'>{error}</div> : null
        }
        {
          post ? 
          <div className='loading'><Sentry size={33}/></div> 
          : 
          <Button type="submit" variant="contained" color="secondary" style={{color:'#666'}}>Giriş Yap</Button>
        }
        <div className='bottomarea'>
          {/* <Link to="/sifremi-unuttum" >Şifremi Unuttum?</Link> */}
          {/* <Link to="/kayit-ol" >Kayıt Ol</Link> */}
        </div>

        <Copyright />
      </form>
    </AuthContainer>
  )
}
