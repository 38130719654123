import React,{useState,useEffect,createRef,useRef} from 'react'
import Menu from '../../components/Navigation/Menu'
import {Content,ContentForm} from '../../styles/Content'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import Title from '../../components/Content/Title'
import Button from '@material-ui/core/Button';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faList, faPlusCircle, faTimes, faTrashAlt, faSdCard, faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import FormInput from '../../components/Form/Input'
import ThreeDot from '../../components/Form/ThreeDot'
import { useHistory } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {AccordionForm} from '../../styles/Content'
import Support from '../../components/Support'
import axios from 'axios'
import { Sentry } from "react-activity";
import { toast } from 'react-toastify'
import Dialog from '../../components/Content/Dialog'
import { useSoundEffect } from '../../components/Content/Sounds';
import { Helmet } from 'react-helmet'

export default function ProductList() {  
  const {errorPlay,successPlay}= useSoundEffect()

  const [search,setSearch]=useState('')

  const [loading,setLoading]=useState(true)
  const [deleteDialog,setDeleteDialog]=useState(false)
  const [deleteId,setDeleteId]=useState(null)
  const [deleteConfirm,setDeleteConfirm]=useState(false)

  const [selectedRows,setSelectedRows]=useState([])

  const [multiDeleteDialog,setMultiDeleteDialog]=useState(false)
  const [multiDeleteConfirm,setMultiDeleteConfirm]=useState(false)
  const confirmMultiDelete=async()=>{
    setMultiDeleteDialog(false)
    setMultiDeleteConfirm(false)
    selectedRows.map( item=>{
      deleteProduct(item.product_id,true)
    })
  }
  
  const multiDelete=()=>{
    if(multiDeleteConfirm==false && multiDeleteDialog==false){
      setMultiDeleteDialog(true)
    }
  }
  const confirmDelete=async()=>{
    let set=await deleteProduct(deleteId,true)
  }
  const deleteProduct=(id,confirmed=false)=>{
    if(deleteConfirm==false && confirmed==false ){
      setDeleteId(id)
      setDeleteDialog(true)
      return
    }else{
      setDeleteId(null)
      setDeleteConfirm(false)
      setDeleteDialog(false)
    }
    const loader = toast.loading("İşlem sürüyor lütfen bekleyin...",{isLoading: true,closeOnClick: false,pauseOnHover: false,pauseOnFocusLoss: false,draggable: false })
    axios({
      method: 'post',
      url: process.env.REACT_APP_API+process.env.REACT_APP_DELETE_PRODUCT,
      data:{product_id:id},
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
    .then(response=>{
      toast.update(loader, { render: response.data.message, type: "success", isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true });
      setData(data.filter(item=>item.product_id!==id))
      successPlay()
      getData()
    })
    .catch(error=>{
      errorPlay()
      toast.update(loader,{render:error.response.data.message,type:'error',isLoading:false,isLoading: false, autoClose: 3000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,pauseOnFocusLoss: false,draggable: true })
    })
  }
  
  const [name,setName]=useState('')
  const [user,setUser]=useState('')
  const [asid,setAsid]=useState('')
  const [date,setDate]=useState('')

  const [data,setData]=useState([])

  useEffect(()=>{
    if(data.length===0){
      getData()
    }
  },[data])

  const getData=()=>{
    axios({
      method:'get',
      url:process.env.REACT_APP_API+process.env.REACT_APP_PRODUCTS+'?size=999999',
      headers:{
        'Authorization':localStorage.getItem('token'),
        'Content-Type':'application/json'
      }
    })
    .then(response=>{
      console.log(response)
      setData(response.data.products)
      setLoading(false)
    })
    .catch(res=>{
      setLoading(false)
    })
  }

  const ref = createRef(null);

  const {sidebar}=useSelector(state=>state.site)
  const {routes}=useSelector(state=>state.auth)
  const history=useHistory()

  const columns = [
      {
          name: 'ID',
          selector: row => row.product_id,
          sortable: true,
      },
      {
          name: 'Adı',
          selector: row => row.name,
          sortable: true,
      },
      {
          name: 'Stok',
          selector: row => row.stock,
          sortable: true,
      },
      {
          name: 'Fiyat',
          selector: row => row.price+' ₺',
          sortable: true,
      },
      {
          name: <ThreeDot items={
          <>
            <Link to={{
            pathname:'/urun/guncelle',
            state:{
              ids:selectedRows
            }
          }} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faSdCard} /> Ürünlerin Kartı</Link>
          <a onClick={()=>multiDelete()} style={{cursor:'pointer'}}><FontAwesomeIcon icon={faTrashAlt} /> Seçilenleri Sil</a>
          </>
          }  />,
          selector: row => 
          <ThreeDot items={
          <>
            <Link to={`/urun/guncelle/${row.product_id}`} ><FontAwesomeIcon icon={faSdCard} /> Ürün Kartı</Link>
            <a onClick={()=>{deleteProduct(row.id)}}><FontAwesomeIcon icon={faTrashAlt} /> Sil</a>
          </>
          } id={row.id} />,
          sortable: false,
          maxWidth: '3%',
      }
  ];

  const searchRef=useRef(null)

 

  const sortIcon = <FontAwesomeIcon style={{transition:'300ms all',fontWeight:'400'}}   icon={faArrowDown} />;

  const clearFilter=()=>{
    setSearch('')
    setName('')
    setUser('')
    setAsid('')
    setDate('')
  }

  const dataSearchFilter=()=>{
    if(search==''  && name=='' && user=='' ){
      return data
    }else{
      return data.filter(item=>{
        let searchIndex=0
        let mainIndex=0
        if(search!=''){
          if(item.product_id.toString().indexOf(search)>-1){
            return item
          }else if(item.name.toLowerCase().indexOf(search.toLowerCase())>-1){
            return item
          }
        }else{
          return item
        }
        
        

      })
    }
  }

  return (
    <React.Fragment >
      <Menu/>
      <Helmet>
        <title>Ürün Listesi</title>
      </Helmet>
      <Dialog
        open={deleteDialog}
        setOpen={setDeleteDialog}
        setConfirm={confirmDelete}
        selectedId={deleteId}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Ürün Silme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />
      <Dialog
        open={multiDeleteDialog}
        setOpen={setMultiDeleteDialog}
        setConfirm={confirmMultiDelete}
        selectedId={deleteId}
        content={<>Yapacağınız işlem sonrası geri dönüş olmayabilir. Bu işlemi onaylıyor musunuz ?</>} 
        title={<>Toplu Ürün Silme</>}
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}  />

      <Content ref={ref} sidebar={sidebar}  >
        <Support refer={ref} />
        <ContentForm>
          <form onSubmit={(e)=>{e.preventDefault()}}>
          
          
          <Title text={<><FontAwesomeIcon icon={faList} /> Ürün Listesi</>} 
          icon={
          <>
          {
            selectedRows.length>0 ?
            <>
            <Button variant='contained' onClick={()=>{
              history.push({
            pathname:'/urun/guncelle',
            state:{
              ids:selectedRows
            }
          })
            }} style={{backgroundColor:'#9c88ff',color:'#fff',marginRight:15}}><FontAwesomeIcon icon={faSdCard} /> Ürün Kartı</Button>
            <Button variant='contained' onClick={()=>multiDelete()} style={{backgroundColor:'#ff7979',color:'#fff',marginRight:15}}><FontAwesomeIcon icon={faTimesCircle} /> Ürün Sil</Button>

            </>
            :
            <></>
          }
          {
        
            <Button onClick={()=>history.push('/urun/ekle')} variant="contained" color="primary"  ><FontAwesomeIcon icon={faPlusCircle}/>Ürün Oluştur</Button>
            
          }
          </>
          } />
          
          
          <Accordion
          className='accordion-form'
            style={{flexGrow:1,marginBottom:15}}
              defaultExpanded={false}
              >
            <AccordionSummary
              style={{width:'100%',paddingLeft:10,paddingRight:10}}
              expandIcon={<FontAwesomeIcon icon={faArrowDown} />}
              aria-controls="panel1a-content"
              id="panel1a-sad"
            >
              <Typography>Arama Filtresi</Typography>
            </AccordionSummary>
            <AccordionDetails>
              
              <AccordionForm>
                  <Button onClick={()=>clearFilter()} variant="contained" className='clear-filter' ><FontAwesomeIcon icon={faTimes}/><span className='text'></span></Button>
                  <div className='input-group input-search'>
                    <FormInput 
                    value={search}
                    setValue={setSearch}
                    nomargin={true}
                    placeholder="Tüm alanlarda ara" 
                    refer={searchRef}
                    />
                  </div>

                

                  



              </AccordionForm>

            </AccordionDetails>
          </Accordion>


          <DataTable
              pagination
              responsive
              paginationComponentOptions={{ noRowsPerPage: true }}
              sortIcon={sortIcon}
              defaultSortFieldId={1}
              onRowDoubleClicked={(e,rowData)=>{
                history.push(`/urun/guncelle/${e.product_id}`)
              }}
              selectableRows
              onSelectedRowsChange={(e)=>{
                setSelectedRows(e.selectedRows)
              }}
              export
              exportHeaders
              highlightOnHover
              pointerOnHover
              noDataComponent={<div style={{padding:20}}>{loading ? <Sentry size={34} />: <h1>Veri bulunamadı</h1>}</div>}
              columns={columns}
              data={
                dataSearchFilter()
              }
          />
          
          
          <div className='addButton'>
            {/* <Button type='submit' color="primary" variant="contained">Ekipman Oluştur</Button> */}
          </div>

          </form>

        </ContentForm>

      </Content>
    </React.Fragment>
  )
}