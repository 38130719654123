import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './components/stores/index';
import {Provider} from 'react-redux'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';


const rootElement = document.getElementById("root");
const { palette } = createTheme();

const theme = createTheme({
  palette: {
    secondary: {
      main: '#83dced'
    },
    primary:{
      main:'#BDE0FE'
    },
    error:{
      main:'#f50057'
    },
    success: {
      main : "#bac778",
    },
    
  },
  
});

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
          
        </BrowserRouter>
      </Provider>
    </MuiThemeProvider>, rootElement);
} else {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </MuiThemeProvider>
    , rootElement);
}