import Test from './views/Test';
import Home from './views/Home';
import Login from './views/Authentication/Login';
import Logout from './views/Authentication/Logout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faHome, faCog, faClipboardCheck, faHockeyPuck, faAlignRight, faCalendarCheck, faImages } from '@fortawesome/free-solid-svg-icons';
import AkinsoftPng from './images/akinsoft.png'

import Settings from './views/Settings';
import CategoryList from './views/Category/CategoryList';
import CategoryAdd from './views/Category/CategoryAdd';
import CategoryDetail from './views/Category/CategoryDetail';
import CategoryCard from './views/Category/CategoryMulipleDetail';

import ProductList from './views/Product/ProductList';
import ProductCard from './views/Product/ProductMulipleDetail';
import ProductAdd from './views/Product/ProductAdd';
import UnitList from './views/Unit/UnitList';
import UnitAdd from './views/Unit/UnitAdd';
import CatalogList from './views/Catalog/CatalogList';
import CatalogAdd from './views/Catalog/CatalogAdd';
import ReferanceList from './views/Referance/ReferanceList';
import ReferanceAdd from './views/Referance/ReferanceAdd';
import WarrantyList from './views/Warranty/WarrantyList';
import WarrantyAdd from './views/Warranty/WarrantyAdd';
import { faBuffer } from '@fortawesome/free-brands-svg-icons';
import OfferList from './views/Offer/OfferList';
import OfferAdd from './views/Offer/OfferAdd';
import ProductDetail from './views/Product/ProductDetail';


const mainRoutes = [
  {
    path:'/giris-yap',
    exact:true,
    component:Login,
    props:{},
    auth:false
  },
  {
    path:'/test',
    label:'TEST',
    exact:false,
    component:Test,
    props:{},
    auth:false
  },
  {
    path:'/cikis-yap',
    exact:true,
    component:Logout,
    props:{},
    auth:false
  },
  {
    path:'/',
    exact:true, 
    component:Home,
    props:{},
    label:'Ana Sayfa',
    icon:<FontAwesomeIcon icon={faHome} />,
    auth:true
  },
  {
    path:'/kategori',
    exact:true, 
    component:CategoryList,
    props:{
      items:[
        {label:'Yeni Kategori Oluştur',exact:true,path:'/ekle',component:CategoryAdd},
        {label:'Kategori Listesi',exact:true,path:'/listesi',component:CategoryList},
      ]
    },
    label:'Kategori',
    icon:<FontAwesomeIcon icon={faClipboardCheck} />,
    auth:true
  },
  {
    path:'/kategori/guncelle/:id',
    exact:true, 
    component:CategoryDetail,
    props:{ 
    },
    auth:true
  },
  {
    path:'/urun',
    exact:true, 
    component:CategoryList,
    props:{
      items:[
        {label:'Yeni Ürün Oluştur',exact:true,path:'/ekle',component:ProductAdd},
        {label:'Ürün Listesi',exact:true,path:'/listesi',component:ProductList},
      ]
    },
    label:'Ürün',
    icon:<FontAwesomeIcon icon={faHockeyPuck} />,
    auth:true
  },
  {
    path:'/urun/guncelle/:id',
    label:'Ürün Güncelle',
    exact:true, 
    component:ProductDetail,
    props:{ 
    },
    auth:true
  },
  {
    path:'/urun/guncelle',
    label:'Ürün Güncelle',
    exact:true, 
    component:ProductCard,
    props:{ 
    },
    auth:true
  },
  {
    path:'/kategori/guncelle',
    label:'Kategori Güncelle',
    exact:true, 
    component:CategoryCard,
    props:{ 
    },
    auth:true
  },
  {
    path:'/birim',
    exact:true, 
    component:CategoryList,
    props:{
      items:[
        {label:'Yeni Birim Oluştur',exact:true,path:'/ekle',component:UnitAdd},
        {label:'Birim Listesi',exact:true,path:'/listesi',component:UnitList},
      ]
    },
    label:'Birim',
    icon:<FontAwesomeIcon icon={faAlignRight} />,
    auth:true
  },
  {
    path:'/katalog',
    exact:true, 
    component:CatalogList,
    props:{
      items:[
        {label:'Yeni Katalog Oluştur',exact:true,path:'/ekle',component:CatalogAdd},
        {label:'Katalog Listesi',exact:true,path:'/listesi',component:CatalogList},
      ]
    },
    label:'Katalog',
    icon:<FontAwesomeIcon icon={faImages} />,
    auth:true
  },
  {
    path:'/referans',
    exact:true, 
    component:ReferanceList,
    props:{
      items:[
        {label:'Yeni Referans Oluştur',exact:true,path:'/ekle',component:ReferanceAdd},
        {label:'Referans Listesi',exact:true,path:'/listesi',component:ReferanceList},
      ]
    },
    label:'Referans',
    icon:<FontAwesomeIcon icon={faImages} />,
    auth:true
  },
  
  // {
  //   path:'/garanti',
  //   exact:true, 
  //   component:CategoryList,
  //   props:{
  //     items:[
  //       {label:'Yeni Garanti Oluştur',exact:true,path:'/ekle',component:WarrantyAdd},
  //       {label:'Garanti Listesi',exact:true,path:'/listesi',component:WarrantyList},
  //     ]
  //   },
  //   label:'Garanti',
  //   icon:<FontAwesomeIcon icon={faCalendarCheck} />,
  //   auth:true
  // },
  // {
  //   path:'/kampanya',
  //   exact:true, 
  //   component:CategoryList,
  //   props:{
  //     items:[
  //       {label:'Yeni Kampanya Oluştur',exact:true,path:'/ekle',component:OfferAdd},
  //       {label:'Kampanya Listesi',exact:true,path:'/listesi',component:OfferList},
  //     ]
  //   },
  //   label:'Kampanya',
  //   icon:<FontAwesomeIcon icon={faBuffer} />,
  //   auth:true
  // },
  // {
  //   path:'/wolvox',
  //   exact:true, 
  //   component:Home,
  //   props:{
  //     icon:'svg',
  //     items:[
  //       {label:'Stok aktarımı',exact:true,path:'/olustur',component:Home},
  //       {label:'Kategori aktarımı',exact:true,path:'/listesi',component:Home},
  //       {label:'Sipariş aktarımı',exact:true,path:'/aktarim',component:Home},
  //     ]
  //   },
  //   label:'Akınsoft Wolvox',
  //   icon:<><img src={AkinsoftPng} /></>,
  //   auth:true
  // },
  {
    path:'/ayarlar',
    exact:true, 
    label:'Ayarlar',
    icon:<FontAwesomeIcon icon={faCog} />,
    component:Settings,
    props:{},
    auth:true
  },
  
]
const Routes=mainRoutes

export {Routes}